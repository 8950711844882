import { useContext, useEffect, useState } from "react";
import "./dashboard.style.css";
import { UserContext } from "../../context/user.context";
import { FiltersContext } from "../../context/filters.context";
import Table from "../../components/table/table.component";
import PaginationComponent from "../../components/pagination/pagination.component";
import Filters from "../../components/filters/filters.component";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "@mui/material";
import FeaturesMenu from "../../components/features-menu/features-menu.component";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const API = "https://backend.reachtive.com/api";

export default function Dashboard() {
  const { status, token, changeUserState } = useContext(UserContext);
  const { filters, handleFilterChange, setFilters } =
    useContext(FiltersContext);
  const [selectedIds, setSelectedIds] = useState([]);
  const [sessionData, setSessionData] = useState(null);
  const [maxData, setMaxData] = useState({});
  const [vendors, setVendors] = useState([]);
  const [language, setLanguage] = useState([]);
  const [sites, setSites] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectType, setSelectType] = useState("none");
  const [maxRows, setMaxRows] = useState(0);

  const updateNote = () => toast(`Site Updated.`);
  const deleteNote = () => toast(`Site Deleted.`);
  const exportNote = () => toast("Downloading File");
  const uploadNote = (message, updated, unaffected, inserted) =>
    toast(
      <div>
        {message} <br /> {updated} files updated <br /> {unaffected} files
        unaffected. <br /> {inserted} files inserted.
      </div>
    );
  const noSitesNote = (message) => toast(message);
  const addSiteNote = () => toast("Site Added");
  const bulkDeleteNote = () => toast("Sites Deleted");

  useEffect(() => {
    if (status) getSession(token);
  }, []);

  useEffect(() => {
    fetchSites(token, filters);
  }, [page, filters]);

  function handlePageChange(page) {
    setPage(page);
    setSelectedIds([]);
  }

  async function handleAddSite(site) {
    const formData = new FormData();
    formData.append("url", site.url);
    formData.append("categories", site.categories);
    formData.append("vendor", site.vendor);
    formData.append("language", site.language);
    formData.append("dr", site.dr);
    formData.append("rd", site.rd);
    formData.append("traffic", site.traffic);
    formData.append("backlinks", site.backlinks);
    formData.append("content_length", site.content_length);
    formData.append("price_general", site.price_general);
    formData.append("price_cbd", site.price_cbd);
    formData.append("price_casino", site.price_casino);
    formData.append("price_crypto", site.price_crypto);
    formData.append("price_language_other", site.price_language_other);
    formData.append("price_link_insert", site.price_link_insert);
    formData.append("link_type", site.link_type);
    formData.append("link_validity", site.link_validity);
    formData.append("note", site.note);

    const res = await fetch(`${API}/site/add`, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: formData,
    });
    const data = await res.json();
    if (data.message === "Token is invalid.") {
      changeUserState(false, null);
      return;
    }
    addSiteNote();
    getSession(token);
    fetchSites(token, filters);
  }

  async function handleBulkDelete() {
    const formData = new FormData();
    formData.append(`ids`, selectedIds.join(","));
    const res = await fetch(`${API}/sites/delete`, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: formData,
    });

    const data = await res.json();
    if (data.message === "Token is invalid.") {
      console.log(data.message + "deleteBulksite");
      changeUserState(false, null);
      return;
    }
    if (page === lastPage) setPage((page) => page - 1);
    else fetchSites(token, filters);
    setSelectedIds([]);
    setSelectAll(false);
    setSelectType("none");
    getSession(token);
    bulkDeleteNote();
  }

  async function handleDeleteSite(id) {
    const res = await fetch(`${API}/site/delete/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: token,
      },
    });
    const data = await res.json();

    if (data.message === "Token is invalid.") {
      console.log(data.message + "deletsite");
      changeUserState(false, null);
      return;
    }
    deleteNote();
    fetchSites(token, filters);
    getSession(token);
    if (sites.length === 1) setPage((p) => p - 1);
  }

  async function handleEditFile(site) {
    const formData = new FormData();
    formData.append("dr", site.dr);
    formData.append("rd", site.rd);
    formData.append("traffic", site.traffic);
    formData.append("backlinks", site.backlinks);
    formData.append("content_length", site.content_length);
    formData.append("price_general", site.price_general);
    formData.append("price_cbd", site.price_cbd);
    formData.append("price_casino", site.price_casino);
    formData.append("price_crypto", site.price_crypto);
    formData.append("price_language_other", site.price_language_other);
    formData.append("price_link_insert", site.price_link_insert);
    formData.append("link_type", site.link_type);
    formData.append("link_validity", site.link_validity);
    formData.append("note", site.note);

    const res = await fetch(`${API}/site/update/${site.id}`, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: formData,
    });
    const data = await res.json();

    if (data.message === "Token is invalid.") {
      console.log(data.message + "edit");
      changeUserState(false, null);
      return;
    }
    getSession(token);
    updateNote();
  }

  async function handleUploadFile(file) {
    const formData = new FormData();
    formData.append("file", file);

    const res = await fetch(`${API}/import`, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: formData,
    });

    const data = await res.json();

    if (data.message === "Token is invalid.") {
      changeUserState(false, null);
      return;
    }

    fetchSites(token, filters);
    getSession(token);
    if (data.message === "Import has been done.") {
      uploadNote(data.message, data.updated, data.unaffected, data.inserted);
    } else alert(data.message);
  }

  async function handleExportFile() {
    exportNote();

    let queryParameters = "";
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== "" && key !== "rows")
        queryParameters += `${encodeURIComponent(key)}=${encodeURIComponent(
          value
        )}&`;
    });

    const apiAdress =
      `${API}/sites?page=${1}&rows=${maxRows}` + queryParameters;
    try {
      const response = await fetch(`${apiAdress}`, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.message === "Token is invalid.") {
        changeUserState(false, null);
        return;
      }
      if (data.sites) {
        const EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const EXCEL_EXTENSION = ".xlsx";
        async function handleDownload() {
          const worksheet = XLSX.utils.json_to_sheet(data.sites);
          const workbook = {
            Sheets: {
              data: worksheet,
            },
            SheetNames: ["data"],
          };
          const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });

          const exportData = new Blob([excelBuffer], { type: EXCEL_TYPE });

          try {
            saveAs(exportData, "Sites_Data" + EXCEL_EXTENSION);
          } catch (error) {
            console.error("Error downloading the file:", error);
          }
        }
        handleDownload();
      }
      if (data.message === "0 site found.") {
        noSitesNote(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchSites(token, filters) {
    let queryParameters = "";

    Object.entries(filters).forEach(([key, value]) => {
      if (value !== "")
        queryParameters += `${encodeURIComponent(key)}=${encodeURIComponent(
          value
        )}&`;
    });
    const apiAdress = `${API}/sites?page=${page}&` + queryParameters;
    try {
      const response = await fetch(`${apiAdress}`, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.message === "Token is invalid.") {
        changeUserState(false, null);
        return;
      }
      if (data.sites) {
        setMaxRows(data.rows);
        setSites(data.sites);
        setLastPage(data.page_last);
      }
      if (data.message === "0 site found.") {
        noSitesNote(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getSession(token) {
    try {
      const response = await fetch(`${API}/session`, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.message === "Token is invalid.") {
        console.log(data.message + "getsession");
        changeUserState(false, null);
        return;
      }
      const obj1 = filters;
      const obj2 = data.data.max;
      for (const key in obj1) {
        if (
          obj1.hasOwnProperty(key) &&
          key.includes("_max") &&
          obj2.hasOwnProperty(key.replace("_max", ""))
        ) {
          obj1[key] = obj2[key.replace("_max", "")];
        }
      }
      setVendors(data.data.vendors);
      setLanguage(language);
      setMaxData(obj2);
      setFilters(obj1);
      setSessionData(data.data);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  return (
    <div className="dashboard">
      <div className="logout" style={{ textAlign: "right" }}>
        <Button
          style={{ margin: "10px", marginLeft: "auto" }}
          size="large"
          variant="contained"
          onClick={() => {
            changeUserState(false, null);
          }}
        >
          LOGOUT
        </Button>
      </div>
      <ToastContainer />
      <Filters
        maxData={maxData}
        vendors={vendors}
        onPageChange={handlePageChange}
        language={language}
      />
      <FeaturesMenu
        sites={sites}
        onUploadFile={handleUploadFile}
        handleBulkDelete={handleBulkDelete}
        onAddSite={handleAddSite}
        selectedIds={selectedIds}
        handleExportFile={handleExportFile}
      />
      <Table
        selectType={selectType}
        setSelectType={setSelectType}
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        setSelectedIds={setSelectedIds}
        onEditSite={handleEditFile}
        onDeleteSite={handleDeleteSite}
        sites={sites}
      />
      <PaginationComponent
        lastPage={lastPage}
        page={page}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
